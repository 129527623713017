import React, { useEffect, forwardRef } from "react";
import { gsap } from "gsap";

const Header = forwardRef(({ onLearnMoreClick }, ref) => {
  useEffect(() => {
    // GSAP Animations
    gsap.fromTo(
      ".hero-heading ",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
    );
    gsap.fromTo(
      ".hero-heading span",
      { opacity: 0, y: 50 },
      { opacity: 1, y: 0, duration: 1, stagger: 0.2 }
    );

    gsap.fromTo(
      ".hero-img",
      { opacity: 0, scale: 0.9 },
      { opacity: 1, scale: 1, duration: 1, delay: 0.5 }
    );
    gsap.fromTo(
      ".hero-p",
      { opacity: 0, scale: 0.9 },
      { opacity: 1, scale: 1, duration: 1, delay: 0.5 }
    );

    gsap.fromTo(
      ".hero-button",
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, duration: 0.6, delay: 1 }
    );
  }, []);

  const scrollToAbout = () => {
    if (ref && ref.current) {
      const yOffset = -70; // Adjust this value according to your header height
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      console.error("ref is not defined");
    }
  };

  return (
    <section className="px-4 pt-20">
      <div className="md:text-5xl text-4xl text-left md:text-center md:p-10">
        <h1 className="font-semibold text-black hero-heading  ">
          PSL Irrigation:
          <br />
          <div className="mt-6 md:mt-4 max-md:mt-0"></div>
          <span>Your Partner in </span>
          <span className="text-custom-color rounded-3xl bg-custom-green bg-opacity-90 px-2 py-1 md:px-4 md:py-2 inline-block">
            Sustainable Outdoor Solutions
          </span>
        </h1>
      </div>
      <div className="my-5 flex flex-col xl:flex-row justify-center md:px-20 md:w-[80%] mx-auto w-full">
        <img
          loading="lazy"
          src="./images/project-2.jpg"
          alt="Landscaping and irrigation project"
          className="object-cover w-full md:w-[800px] h-auto md:h-[200px] mb-4 md:mb-0 rounded-3xl md:aspect-[3.16] hero-img"
        />
        <div className="md:ml-3 lg:w-[50%]">
          <p className="text-justify text-lg md:text-xl text-slate-700 hero-p">
            Discover reliable, efficient, and sustainable irrigation solutions
            tailored to meet your unique needs. At PSL Irrigation, we are
            committed to enhancing your landscape/irrigation with our expert
            services.
          </p>
          <button
            onClick={scrollToAbout}
            className="mt-4 md:mt-2 lg:mt-13 px-5 py-2 text-white font-semibold bg-btn-color rounded-2xl hover:bg-[#4a6a2c] hero-button"
          >
            Learn More
          </button>
        </div>
      </div>
      <img
        loading="lazy"
        src="./images/project-1.jpg"
        alt="Landscaping and irrigation project"
        className="object-cover md:w-[80%] lg:w-[80%] w-full rounded-3xl md:aspect-[3.16] mx-auto hero-img"
      />
    </section>
  );
});

export default Header;
