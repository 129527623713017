import React, { useRef } from "react";
import AboutSection from "./AboutSection";
import Services from "./Services";
import Header from "./Header";
import ContactUs from "./ContactUs";
import BlogSection from "./BlogSection";
import Footer from "./Footer";
import Gallery from "./Gallery";

import NavBar from "./NavBar";

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const serviceRef = useRef(null);
  const galleryRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div>
      <NavBar
        homeRef={homeRef}
        aboutRef={aboutRef}
        serviceRef={serviceRef}
        galleryRef={galleryRef}
        contactRef={contactRef}
      />
      <section ref={homeRef} className="pt-16 md:pt-0">
        <Header ref={aboutRef} />
      </section>
      <section ref={aboutRef} className="pt-10">
        <AboutSection />
      </section>
      <section ref={serviceRef} className="pt-10">
        <Services />
      </section>
      <section ref={galleryRef}>
        <Gallery />
      </section>
      <section ref={contactRef}>
        <ContactUs />
      </section>
      <BlogSection />
      <Footer ref={homeRef} />
    </div>
  );
}

export default App;
