export const contactData = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/02fac0a126519b919d8dfd6271a983dbd20aa4d5acedd9bba073ad37c1d23f0e?placeholderIfAbsent=true&apiKey=9feddab0804e41dab721cfead4b39bfe",
    text: "206-278-1895",
    type: "phone",
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/f33a12af71453b99485ac9031fecc220eb9409e1999afd1ffbb8eef1e6b8f8f2?placeholderIfAbsent=true&apiKey=9feddab0804e41dab721cfead4b39bfe",
    text: "psl.irrigation.wa@gmail.com",
    type: "email",
  },
];
