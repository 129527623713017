import React, { useState, useEffect, useRef } from "react";

const NavBar = ({ homeRef, aboutRef, serviceRef, galleryRef, contactRef }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollTop = useRef(0);

  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const scrollToSection = (ref) => {
    const yOffset = -70; // Adjust this value according to your header height
    const y =
      ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
    setIsNavOpen(false); // Close the menu after clicking
  };

  // Use effect to manage body scroll locking
  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "auto";
    }

    // Clean up the effect when component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isNavOpen]);

  // Detect scroll direction
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop.current) {
        // Scrolling down
        setShowNavbar(false);
      } else {
        // Scrolling up
        setShowNavbar(true);
      }
      lastScrollTop.current = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
      className={`bg-white shadow-md fixed top-0 w-full z-50 transition-transform duration-300 ${
        showNavbar ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="max-w-screen-xl flex flex-wrap  items-center justify-between mx-auto p-3">
        <a
          href="#home"
          className="flex items-center space-x-2 rtl:space-x-reverse"
          onClick={() => scrollToSection(homeRef)}
        >
          <img src="./images/logo.png" alt="logo-ct" className="w-20" />
        </a>
        <button
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-green-500 rounded-lg md:hidden hover:bg-custom-color focus:outline-none focus:ring-2 focus:ring-white-200 dark:text-white-400 dark:hover:bg-custom-green dark:focus:ring-green-600"
          aria-controls="navbar-default"
          aria-expanded={isNavOpen}
          onClick={handleToggle}
        >
          <span className="sr-only">
            {isNavOpen ? "Close menu" : "Open menu"}
          </span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            {isNavOpen ? (
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>
        <div
          className={`${
            isNavOpen ? "block" : "hidden"
          } fixed inset-0 bg-white z-40 p-4 md:relative md:bg-transparent md:p-0 md:max-h-screen md:overflow-hidden md:flex md:items-center md:justify-center`}
          id="navbar-default"
        >
          <button
            type="button"
            className="absolute top-4 right-4 text-black hover:text-[#4a6a2c] md:hidden"
            onClick={handleToggle}
            aria-label="Close menu"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
          <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border bg-white rounded-3xl md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0">
            <li>
              <a
                href="#home"
                className="block py-2 px-3 text-black border border-lime-600 rounded-2xl hover:bg-custom-green"
                aria-current="page"
                onClick={() => scrollToSection(homeRef)}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className="block py-2 px-3 text-black border border-lime-600 rounded-2xl hover:bg-custom-green"
                onClick={() => scrollToSection(aboutRef)}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#service"
                className="block py-2 px-3 text-black border border-lime-600 rounded-2xl hover:bg-custom-green"
                onClick={() => scrollToSection(serviceRef)}
              >
                Service
              </a>
            </li>
            <li>
              <a
                href="#gallery"
                className="block py-2 px-3 text-black border border-lime-600 rounded-2xl hover:bg-custom-green"
                onClick={() => scrollToSection(galleryRef)}
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className="block py-2 px-3 text-white border bg-btn-color rounded-2xl"
                onClick={() => scrollToSection(contactRef)}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
      <hr className="border-t border-t-custom-color bg-secondary-400 mt-2" />
    </nav>
  );
};

export default NavBar;
