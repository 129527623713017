import React from "react";

function ContactItem({ icon, text, type }) {
  const handleClick = () => {
    if (type === "phone") {
      window.location.href = `tel:${text}`;
    } else if (type === "email") {
      window.location.href = `mailto:${text}`;
    }
  };
  return (
    <div className="flex gap-6 mt-12 whitespace-nowrap max-md:mt-10">
      <img
        loading="lazy"
        src={icon}
        alt=""
        className="object-contain shrink-0 w-6 aspect-square"
      />
      <button className="text-white hover:text-green-500" onClick={handleClick}>
        {text}
      </button>
    </div>
  );
}

export default ContactItem;
