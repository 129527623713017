export default function Gallery() {
  const data = [
    {
      imageLink: "./images/gallery-1.webp",
    },
    {
      imageLink: "./images/gallery-2.webp",
    },
    {
      imageLink: "./images/gallery-3.webp",
    },
    {
      imageLink: "./images/gallery-4.webp",
    },
    {
      imageLink: "./images/gallery-5.webp",
    },
    {
      imageLink: "./images/gallery-6.webp",
    },
  ];

  return (
    <section className=" px-5 py-5 my-3  pt-10">
      <div className="text-center mb-5">
        <h2 className="text-base font-semibold uppercase text-custom-color">
          Our Work
        </h2>
        <h1 className="mt-2  text-4xl font-semibold text-black">
          Latest Gallery
        </h1>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 p-6">
        {data.map(({ imageLink }, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg shadow-md border border-gray-200 hover:shadow-xl transform hover:scale-105 transition-all duration-300 ease-in-out"
          >
            <img
              className="h-48 w-full object-cover"
              src={imageLink}
              alt="gallery-photo"
            />
          </div>
        ))}
      </div>
    </section>
  );
}
