import React from "react";
import BlogPost from "./BlogPost";
const blogPosts = [
  {
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/221c2e394ca027d8e0e69e30f578a9072ad77d85e039745ae628114f8a5d6e16?placeholderIfAbsent=true&apiKey=9feddab0804e41dab721cfead4b39bfe",
    title:
      "For Earth Day and every day, water-saving solutions for your landscape!",
    description:
      "Save time, money and valuable water with a well-designed irrigation system",
    author: "PSL Irrigation",
    date: "April 17, 2023 6:00 am",
    link: "https://www.bainbridgereview.com/local-marketplace/for-earth-day-and-every-day-water-saving-solutions-for-your-landscape/",
  },
  {
    imageSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/4bb90e844d2e948f2a06c0a3b6179f24f75c9208923c8e3c960084165003d057?placeholderIfAbsent=true&apiKey=9feddab0804e41dab721cfead4b39bfe",
    title:
      "This water-conservation tool is good for your garden – and the environment!",
    description:
      "A well-designed, quality irrigation system will keep you and your plants happy",
    author: "PSL Irrigation",
    date: "April 12, 2021 6:00 am",
    link: "https://www.bainbridgereview.com/marketplace/this-water-conservation-tool-is-good-for-your-garden-and-the-environment/",
  },
];

export default function BlogSection() {
  return (
    <section className="py-4 mt-10 flex flex-col items-center">
      <h2 className=" text-base font-semibold text-custom-color">
        FROM THE BLOG
      </h2>
      <h1 className="text-4xl font-semibold text-black">Learn more</h1>
      <div className="self-stretch mt-12 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {blogPosts.map((post, index) => (
            <div
              key={index}
              className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full"
            >
              <BlogPost {...post} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
