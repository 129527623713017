import { useEffect, useRef, useState } from "react";

export default function AboutSection() {
  const handleCall = () => {
    window.location.href = "tel:206-278-1895";
  };
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const imageContainerRef = useRef(null);
  const imageRefs = useRef([]);

  useEffect(() => {
    const container = imageContainerRef.current;
    if (!container || !imageRefs.current.length) return;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollDirection = scrollTop > lastScrollTop ? "down" : "up";
      setLastScrollTop(scrollTop);

      // Get the bounding rectangle of the image section
      const sectionRect = container.getBoundingClientRect();
      const isInView =
        sectionRect.top >= 0 && sectionRect.bottom <= window.innerHeight;

      if (isInView) {
        if (scrollDirection === "down") {
          // Scroll down - move to the next image
          setCurrentImageIndex((prevIndex) => {
            const newIndex = Math.min(
              prevIndex + 1,
              imageRefs.current.length - 1
            );
            container.scrollTo({
              left: imageRefs.current[newIndex].offsetLeft,
              behavior: "smooth",
            });
            return newIndex;
          });
        } else {
          // Scroll up - move to the previous image
          setCurrentImageIndex((prevIndex) => {
            const newIndex = Math.max(prevIndex - 1, 0);
            container.scrollTo({
              left: imageRefs.current[newIndex].offsetLeft,
              behavior: "smooth",
            });
            return newIndex;
          });
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop, currentImageIndex]);

  return (
    <section className="mt-48 mb-10 w-full max-w-[1400px] mx-auto px-4 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col md:flex-row gap-5">
        {/* Text Section */}
        <div className="flex flex-col w-full md:w-[59%]">
          <div className="flex flex-col self-stretch my-auto max-md:mt-10">
            <h2 className="text-base font-semibold text-custom-color">
              ABOUT PSL IRRIGATION
            </h2>
            <h3 className="mt-7 text-4xl font-semibold text-black">
              Irrigation Partner You Can Trust
            </h3>
            <p className="mt-2.5 text-lg text-slate-600">
              Our team, led by Bill and Sean Crane, combines horticultural
              expertise with cutting-edge technology to deliver high-quality
              solutions that ensure your lawns and gardens stay lush and
              healthy. We pride ourselves on our innovative techniques and
              exceptional customer service.{" "}
              <span className="text-btn-color font-bold">
                Serving Bainbridge Island, WA, and surrounding areas
              </span>
              , we are dedicated to meeting the needs of our local community.
            </p>
            <p className="mt-6 text-lg font-bold text-btn-color">
              Let's work together! Drop an email or phone call to get started.
            </p>
            <button
              onClick={handleCall}
              className="self-start px-6 py-3 mt-5 text-base font-bold leading-6 text-white bg-btn-color rounded-2xl hover:bg-[#4a6a2c]"
            >
              Contact Now
            </button>
          </div>
        </div>
        <div className="hidden md:block md:w-[40%]">
          <img
            loading="lazy"
            src="./images/person-1.webp"
            alt="person"
            className="w-full h-full object-cover rounded-3xl"
          />
        </div>
        {/* Image Scroller for Small Screens */}
        <div className="md:hidden w-full flex flex-col gap-6">
          <div
            className="flex overflow-x-auto no-scrollbar gap-4"
            ref={imageContainerRef}
          >
            <img
              loading="lazy"
              src="./images/person-1.webp"
              alt="person"
              className="w-full h-full object-fill rounded-3xl" // Adjust width and height as needed
              ref={(el) => (imageRefs.current[0] = el)}
            />
            <img
              loading="lazy"
              src="./images/person-2.png"
              alt="person"
              className="w-[300px] h-full object-fill rounded-3xl" // Adjust width and height as needed
              ref={(el) => (imageRefs.current[1] = el)}
            />
            {/* Add more images as needed */}
          </div>
        </div>
      </div>
    </section>
  );
}
