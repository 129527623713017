import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./service.css";

gsap.registerPlugin(ScrollTrigger);

const specializationData = [
  {
    title: "Installation",
    description:
      "We are set up and capable to design and create an entirely new and modern system, capable of meeting all of your potential demands.",
  },
  {
    title: "Modification",
    description:
      "We can also build off of existing irrigation architecture to accommodate new areas and plantings in need of water.",
  },
  {
    title: "Repair",
    description:
      "Systems can fail or break for any number of reasons. Once we have you the schedule we are committed to getting you operational as soon as possible",
  },
  {
    title: "Modernization",
    description:
      "There are many new technologies that will give you a smarter, more efficient system. Such as our weather smart controller, which in cooperation with one another can decrease annual water consumption by as much as 30%.",
  },
  {
    title: "Maintenance",
    description:
      "Our annual maintenance contract provides you with year round remote monitoring of your system and includes one free service call for any minor repairs. As well as a spring start up and winterization of your system.",
  },
];

export default function SpecializationSection() {
  useEffect(() => {
    // Select all specialization items
    const items = gsap.utils.toArray(".specialization-item");

    // Set up animations for each item
    items.forEach((item) => {
      gsap.fromTo(
        item,
        { opacity: 0, y: 50 }, // Start state
        {
          opacity: 1,
          y: 0,
          duration: 1.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: item,
            start: "top 85%",
            end: "bottom 15%",
            scrub: 0.8,
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  return (
    <section className="pt-10 px-4 flex flex-col text-4xl text-black rounded-none">
      <h2 className=" self-center text-base font-semibold text-custom-color">
        WHAT WE DO
      </h2>
      <h3 className="self-center mt-2 font-semibold">Our specialization</h3>
      <div className="w-full max-w-[1400px] mx-auto px-4 max-md:mt-10 max-md:max-w-full">
        <hr className="top-0 border-t border-t-custom-color bg-secondary-400 mt-2" />
        {specializationData.map((item, index) => (
          <React.Fragment key={item.title}>
            <SpecializationItem
              title={item.title}
              description={item.description}
            />
            {index < specializationData.length - 1 && (
              <hr className="top-0 border-t border-t-custom-color bg-secondary-400" />
            )}
          </React.Fragment>
        ))}
      </div>
    </section>
  );
}

function SpecializationItem({ title, description }) {
  return (
    <div className="specialization-item flex flex-col w-full px-4 py-6">
      <div className="flex flex-col lg:flex-row w-full items-start lg:items-center justify-between">
        <h4 className="text-4xl font-bold text-left lg:text-left w-full lg:w-auto lg:flex-1">
          {title}
        </h4>
        <p className="text-lg text-slate-600 lg:text-justify lg:w-full lg:flex-1 mt-2 lg:mt-0">
          {description}
        </p>
      </div>
    </div>
  );
}
