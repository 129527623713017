import React, { forwardRef } from "react";

const Footer = forwardRef(({ onLearnMoreClick }, ref) => {
  const scrollToTop = () => {
    if (ref && ref.current) {
      const yOffset = -70; // Adjust this value according to your header height
      const y =
        ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    } else {
      console.error("ref is not defined");
    }
  };
  return (
    <footer className="w-full bg-btn-color p-8 mt-5">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 text-center md:justify-between">
        <img src="./images/logo.png" alt="logo-ct" className="w-20" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
          <li>
            <a
              href="#about"
              className="text-white font-normal transition-colors hover:text-green-700 focus:text-green-700"
            >
              About Us
            </a>
          </li>
          <li>
            <a
              href="#services"
              className="text-white font-normal transition-colors hover:text-green-700 focus:text-green-700"
            >
              Services
            </a>
          </li>
          <li>
            <a
              href="#gallery"
              className="text-white font-normal transition-colors hover:text-green-700 focus:text-green-700"
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              href="#learnmore"
              className="text-white font-normal transition-colors hover:text-green-700 focus:text-green-700"
            >
              Learn More
            </a>
          </li>
          <li>
            <a
              href="#contact"
              className="text-white font-normal transition-colors hover:text-green-700 focus:text-green-700"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-white" />
      <p className="text-white text-center font-normal">
        &copy; 2024 PSL Landscaping and Irrigation. All rights reserved.
      </p>
      <button
        onClick={scrollToTop}
        className="fixed bottom-4 right-4 bg-green-700 text-white p-2 rounded-full shadow-lg hover:bg-custom-color focus:outline-none focus:ring-2 focus:ring-green-500"
        aria-label="Back to top"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-10 h-10"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 15l-7-7-7 7"
          />
        </svg>
      </button>
    </footer>
  );
});

export default Footer;
