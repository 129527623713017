import React from "react";
import ContactItem from "./ContactItem";
import { contactData } from "./contactData";
export default function ContactUs() {
  return (
    <section className="px-4 py-4 pt-20">
      <div className="flex flex-col md:flex-row gap-5 justify-center">
        <div className="text-center">
          <h2 className="text-base font-semibold uppercase text-custom-color">
            Contact Us
          </h2>
          <h1 className="mt-2 text-4xl font-semibold text-black">
            Get In Touch
          </h1>
          <p className="text-xl text-slate-700 mt-2">
            Have a project in mind or any questions about our services? We're
            here to assist.
            <br />
            <span>
              Reach out to us anytime from Monday to Saturday, 8:00 AM to 5:00
              PM.
            </span>
          </p>
        </div>
        <section className="flex flex-col  items-start py-9 pr-10 pl-10  w-full md:w-[30%] text-base text-white bg-btn-color rounded-xl max-md:px-5 ">
          <h2 className="text-3xl font-semibold">Contact Information</h2>
          <p className="mt-1.5 text-lg text-stone-300">
            Make a call or email us!
          </p>
          <div className="mt-20 max-md:mt-10">
            {contactData.map((item, index) => (
              <ContactItem
                key={index}
                icon={item.icon}
                text={item.text}
                type={item.type}
              />
            ))}
          </div>
        </section>
      </div>
    </section>
  );
}
