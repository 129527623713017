import React from "react";

function BlogPost({ imageSrc, title, description, author, date, link }) {
  return (
    <article className=" px-auto mx-auto flex flex-col grow text-xs font-medium text-stone-500 max-md:mt-10 max-md:w-[90%]">
      <img
        loading="lazy"
        src={imageSrc}
        alt={title}
        className="object-cover  px-10  w-full aspect-[1.9] max-md:max-w-full "
      />
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="px-10 mt-6 text-lg font-semibold text-black max-md:max-w-full hover:text-btn-color"
      >
        {title}
      </a>
      <p className="px-10 self-start mt-2.5 max-md:max-w-full">{description}</p>
      <p className="px-10 self-start mt-2.5">
        By {author} • {date}
      </p>
    </article>
  );
}

export default BlogPost;
